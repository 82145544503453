@import url(https://fonts.googleapis.com/css?family=Lato:300);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.zoom-bar {
  background: #fafafa;
  border-bottom: 2px solid #828282;
  height: 40px;
  line-height: 40px;
  padding: 5px 10px;
}

.radio-label {
  background: #bdc3c7;
  box-shadow: inset -2px -2px #acb2b7;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Tahoma, Geneva, sans-serif;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  margin-left: 6px;
  text-align: center;
  width: 70px;
}

.radio-label > input[type=radio] {
  height: 0;
  margin: 0;
  visibility: hidden;
  width: 0;
}

.radio-label-active {
  background: #9ea4a8;
  box-shadow: none;
}

.gantt-container {
  height: 600px;
  width: 100%;
}

.message-area {
  background: #ebebeb;
  border: 1px solid #a4a4a4;
  font-family: Courier, monospace;
  height: 200px;
  overflow: auto;
  padding: 10px;
}

.message-area ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.message-area li:before {
  content: ">";
  padding-right: 10px;
}

.gantt_task_line {
  background-color: #444;
  border: #444;
}

.gantt_task_progress {
  background: #000;
}

.logo {
  float: left;
  margin-left: 30px;
  font-size: 20px;
}

.filter-region {
  float: right;
  margin-right: 30px;
}

.progress-percent {
  color: #000;
  font-size: 10px;
  float: left;
  margin-left: -50px;
}

.gantt_task_progress_wrapper, .gantt_task_progress {
  overflow: visible !important;
  overflow: initial !important;
}
.authCont {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    
}

.App-logo {
    max-width:  550px;
    padding: 0 0 18px 0;
}

.divider {
    border: 1px solid rgb(210, 211, 212);
    width: 95%; 
}

.subTitle {
    font-family: 'Lato', sans-serif;
    font-size: 2.8rem;
    padding: 35px 0 0 0;
    text-align: center;
    margin: 0 10px 0 10px;
}

.signin {
    width: 140px;
    height: 50px;
    background-color: black;
    color: white;
    font-weight: 200px;
    border-radius: 8px;
    margin: 35px 0 0 0;
}

.signinForm {
    display: flex;
    flex-flow: column nowrap;
    max-width: 350px;
    height: 250px;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0 10px 0;
}

.nUname, .nPasswd {
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    height: 30px;
    font-size: 20px;
}

.nUname::-webkit-input-placeholder, .nPasswd::-webkit-input-placeholder {
    text-align: center;
}
.nUname:-moz-placeholder, .nPasswd:-moz-placeholder{ /* Firefox 18- */
    text-align: center;  
}
.nUname::-moz-placeholder, .nPasswd::-moz-placeholder{  /* Firefox 19+ */
    text-align: center;  
}
.nUname:-ms-input-placeholder, .nPasswd:-ms-input-placeholder{  
    text-align: center; 
}

.submit, .cancel {
    text-align: center;
    border-radius: 8px;
    width: 40%;
    height: 50px;
    background-color: black;
    color: white;
}

.formCont, .formBtn {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.formBtn {
    padding: 25px 0 0 0;
}

@import url('https://fonts.googleapis.com/css?family=Lato:300');

.authCont {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    
}

.App-logo {
    max-width:  550px;
    padding: 0 0 18px 0;
}

.divider {
    border: 1px solid rgb(210, 211, 212);
    width: 95%; 
}

.subTitle {
    font-family: 'Lato', sans-serif;
    font-size: 2.8rem;
    padding: 35px 0 0 0;
    text-align: center;
    margin: 0 10px 0 10px;
}

.signin {
    width: 140px;
    height: 50px;
    background-color: black;
    color: white;
    font-weight: 200px;
    border-radius: 8px;
    margin: 35px 0 0 0;
}

.signinForm {
    display: flex;
    flex-flow: column nowrap;
    max-width: 350px;
    height: 250px;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0 10px 0;
}

.nUname, .nPasswd {
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    width: 80%;
    height: 30px;
    font-size: 20px;
}

.nUname::-webkit-input-placeholder, .nPasswd::-webkit-input-placeholder {
    text-align: center;
}
.nUname:-moz-placeholder, .nPasswd:-moz-placeholder{ /* Firefox 18- */
    text-align: center;  
}
.nUname::-moz-placeholder, .nPasswd::-moz-placeholder{  /* Firefox 19+ */
    text-align: center;  
}
.nUname:-ms-input-placeholder, .nPasswd:-ms-input-placeholder{  
    text-align: center; 
}

.submit, .cancel {
    text-align: center;
    border-radius: 8px;
    width: 40%;
    height: 50px;
    background-color: black;
    color: white;
}

.formCont, .formBtn {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.formBtn {
    padding: 25px 0 0 0;
}